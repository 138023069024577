import {Suspense, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import {Outlet} from 'react-router-dom'
import { axiosConfig } from 'utils/axiosConfig'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { chengeNotifications } from './reduxStore/Global/GlobalActions'

const App = () => {
  const dispatch = useDispatch()
  function getAllNotifications(){
    axiosConfig.get(`/admin-notifications/all-notifications`,{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
      }
  }).then(res=>{
        dispatch(chengeNotifications(res.data?.data?.data))
    }).catch(err=>{
        console.log(err)
    })
}
  useEffect(()=>{
    getAllNotifications()
  },[])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          {/* <AuthInit> */}
            <Outlet />
            <MasterInit />
          {/* </AuthInit> */}
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
