/* eslint-disable jsx-a11y/anchor-is-valid */
import { t } from 'i18next'
import {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import {useIntl} from 'react-intl'
import { toast } from 'react-toastify'
import { axiosConfig } from 'utils/axiosConfig'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import ContactsUsTable from './ContactsUsTable'
import OrdersTable from './OrdersTable'
import ProvidersTable from './ProvidersTable'
import UsersTable from './UsersTable'

const DashboardPage: FC = () => {
  const {t} =useTranslation()
  const [statistics,setStatistics]=useState<any>({})
  function getStatistics(){
    axiosConfig.get('/admin/statistics',{
      headers: {
          "Authorization": `Bearer ${localStorage.getItem('token')}`
      }
  }).then(res=>{
      console.log(res.data.data)
      setStatistics(res.data.data)
    }).catch(err=>{
      toast.error('Something went Wrong')
    })
  }
  useEffect(()=>{
    getStatistics()
  },[])
  return (
    <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget20
          className='h-md-100 mb-5 mb-xl-10'
          description={t('All Orders')}
          stats={statistics}
          color='#F1416C'
          img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          t={t}
        />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description={t('Completed Orders')}
          icon={false}
          stats={statistics?.complete_orders}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <CardsWidget7
            className='h-md-100 mb-5 mb-xl-10'
            description={t('Users')}
            icon={false}
            stats={statistics?.users_count}
            labelColor='dark'
            textColor='gray-300'
          />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
      <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description={t('Providers')}
          icon={false}
          stats={statistics?.providers_count}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
      <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description={t('Products')}
          icon={false}
          stats={statistics?.products_count}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
      <CardsWidget7
          className='h-md-100 mb-5 mb-xl-10'
          description={t('Orders Price')}
          icon={false}
          stats={statistics?.total_orders_price}
          labelColor='dark'
          textColor='gray-300'
        />
      </div>
    </div>
    <UsersTable items={statistics?.last_users}/>
    <ProvidersTable items={statistics?.last_providers}/>
    <OrdersTable items={statistics?.last_normal_orders} title={t('Last 5 Normal Orders')}/>
    <OrdersTable items={statistics?.last_special_orders} title={t('Last 5 Special Orders')}/>
    <ContactsUsTable items={statistics?.last_contacts}/>
  </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {t} =useTranslation()
  
  return (
    <>
      <PageTitle breadcrumbs={[]}>{t('Dashboard')}</PageTitle>
      <DashboardPage/>
    </>
  )
}

export {DashboardWrapper}
